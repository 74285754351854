<main>
  <header>
    <div class="container">
      <div class="navigation">
        <div class="logo-container">
          <div class="logo" [routerLink]="['']">
            <img src="../../../assets/images/logo.svg" alt="myFeragen">
            <span>{{'COMMON.MY_FERAGEN' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="title-container">
        <span class="mobile-text">myFERAGEN</span>
        <span class="desctop-text">{{'SHARE_PROFILE.SHARED_PROFILE_TITLE' | translate}}</span>

      </div>
      <div class="actions">
        <div class="language-switcher dropdown-container language-dropdown-container"
             [ngClass]="{'showDropdown': showLanguageDropdown}"
             (click)="onShowLanguageDropdown($event)"
             appOutsideClick
             (outsideClick)="onOutsideClick()">
          <i class="fa-light fa-globe"></i>
          <span>{{translate.currentLang | uppercase}}</span>
          <i class="fa-solid fa-chevron-down"></i>
          <ul class="dropdown-options">
            <li (click)="changeLanguage($event, 'de')">{{"LAYOUT.DE" | translate}}</li>
            <li class="divider"></li>
            <li (click)="changeLanguage($event, 'en')">{{"LAYOUT.EN" | translate}}</li>
          </ul>
        </div>
        <a class="explore-btn" href="https://feragen.at/myferagen/" target="_blank">
          <span class="mobile-view">
            <i class="fa-regular fa-circle-info"></i>
          </span>
          <span class="desktop-view">
            {{'SHARE_PROFILE.EXPLORE_MYFERAGEN' | translate}}
          </span>
        </a>
      </div>
    </div>
  </header>
  <mat-sidenav-container>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<app-loader></app-loader>

