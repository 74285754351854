import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenResponse } from '../models/token-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  loggedIn!: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  private get refreshToken(): string {
    return localStorage.getItem('refreshToken') || '';
  }

  storeTokens(response: TokenResponse): void {
    localStorage.setItem('token', response.token);
    localStorage.setItem('refreshToken', response.refreshToken);
  }

  removeTokens(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  requestResetPasswordLink(email: string): Observable<any> {
    return this.http.get(`${environment.forgottenPassword}/${email}`);
  }

  resetPassword(token: string, newPassword?: string): Observable<any> {
    return this.http.post(`${environment.resetPassword}/${token}`, {newPassword});
  }

  onRefreshTokenExpired(): void {
    this.removeTokens();
    this.loggedIn = false;
    this.router.navigate(['login']);


  }

  refreshTokens(): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(environment.refresh, { refreshToken: this.refreshToken })
      .pipe(
        tap((response: TokenResponse) => {
          this.storeTokens(response);
        })
      );
  }

  activateAccount(token: string): Observable<boolean> {
    return this.http.get(`${environment.activateAccount}/${token}`)
    .pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

}
