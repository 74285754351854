import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenResponse } from '../models/token-response.model';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor(
  private http: HttpClient,
  private authenticationService: AuthenticationService,
  private userService: UserService,
  private router: Router,
  ) {}

  login(loginData: any): Observable<boolean> {
    return this.http.post<TokenResponse>(environment.auth,  loginData)
    .pipe(
      mergeMap((response: TokenResponse) => {
        this.authenticationService.storeTokens(response);
        return this.userService.getCurrentUser();
      }),
      map((user) => {
        this.authenticationService.loggedIn = true;
        return true;
      }),
      catchError((response: HttpErrorResponse) => {
        this.authenticationService.loggedIn = false;
        return response.status === 401 ? of(false) : throwError(() => response);
      })
    );
  }

  logout(): Observable<void> {
    return this.http.get(environment.logout)
      .pipe(
        map(() => {
          this.authenticationService.loggedIn = false;
          this.authenticationService.removeTokens();
          this.userService.setCurrentUser(null);
          this.router.navigate(['/login']);
        }),
        catchError((error: HttpErrorResponse) => {
          this.authenticationService.removeTokens();
          return throwError(() => error);
        })
      );
  }


}
