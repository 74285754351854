import { Injectable, Injector } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshing = false;
  private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _preventAuthentication: string[] = [
    environment.forgottenPassword,
    environment.activateAccount,
    environment.refresh,
    environment.resetPassword,
    environment.qrCodeData,
    'docs.my.feragenlab.com',
  ];
  private _preventErrorMessageUrls = [
    {
      url: environment.forgottenPassword,
      status: HttpStatusCode.NotFound,
    },
    {
      url: `${environment.users}/self`,
      status: HttpStatusCode.Unauthorized,
    },
    {
      url: environment.resetPassword,
      status: HttpStatusCode.BadRequest,
    },
    {
      url: environment.share,
      status: HttpStatusCode.InternalServerError,
    },
    {
      url: environment.share,
      status: HttpStatusCode.BadRequest,
    }
  ];


  constructor(
    private injector: Injector,
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const preventAuthentication = !!this._preventAuthentication.find(url => request.url.includes(url));

    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const refreshToken = url.searchParams.get('refreshToken');
    const dogUuid = url.searchParams.get('dogUuid');

    if (this.token && !preventAuthentication) {
      request = this.addAuthToken(request);
    }
    if (token && refreshToken && dogUuid) {
      const router = this.injector.get(Router);
      router.navigate([`animal/${dogUuid}/info`], {queryParamsHandling: 'merge'});
    }
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized && !preventAuthentication) {
            return this.handleAuthError(request, next);
          } else {
            return this.handleError(error);
          }
        })
      );
  }

  addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        SpxAuth: `Bearer ${this.token}`,
      }
    });
  }

  handleAuthError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get<AuthenticationService>(AuthenticationService);

    if (!this.refreshing) {
      this.refreshing = true;
      this._refreshTokenSubject.next(null);

      return authService.refreshTokens()
        .pipe(
          switchMap((result: { token: string, refreshToken: string }) => {
            this.refreshing = false;
            this._refreshTokenSubject.next(result.token);
            return next.handle(this.addAuthToken(request));
          }),
          catchError((error) => {
            this.refreshing = false;
            authService.onRefreshTokenExpired();
            return throwError(() => error);
          })
        );
    } else {
      return this._refreshTokenSubject.pipe(
        filter((token: string) => token != null),
        take(1),
        switchMap((token: string) => {
          return next.handle(this.addAuthToken(request));
        })
      );
    }
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    const preventErrorMessage = this._preventErrorMessageUrls
      .find((endpoint) => (error?.url as string)
        .includes(endpoint.url) && endpoint.status === error.status) || error.status === HttpStatusCode.NotFound || error.status === HttpStatusCode.Unauthorized;
    if (!preventErrorMessage) {
      const message = error.error.errors || error.error.message;
      const errorMsg = Array.isArray(message) ?
        message.map((e: string) => (Object.values(e).map((m: string) => m.replace(/\./g, '_')))
        ).join(',').replace(/,/g, '') : message;
      this.setToastr(errorMsg);
    }
    return throwError(() => error);
  }

  setToastr(message: string): void {
    const toastr = this.injector.get<ToastrService>(ToastrService);
    const translationService = this.injector.get<TranslateService>(TranslateService);
    const type = message.match(/^[A-Z]+[^_]/gm);
    if (type !== null && type[0] !== null) {
      switch (type[0]) {
        case 'ERROR': {
          toastr.error(translationService.instant(`LOGIN.REGISTER.${message}`), 'Error');
          break;
        }
        case 'INFO': {
          toastr.error(translationService.instant(`LOGIN.REGISTER.${message}`), 'Info');
          break;
        }
        default: {
          toastr.error(translationService.instant(`LOGIN.REGISTER.${message}`), 'Error');
          break;
        }
      }
    } else {
      toastr.error(translationService.instant(`LOGIN.REGISTER.${message}`), 'Error');
    }
  }
}
