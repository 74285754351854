<footer class="mobile-content">
  <div class="navigation-menu">
    <ng-container *ngFor="let link of links">
      <a class="navigation-item" [routerLink]="[link.url]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
        <i class="fa-regular" [class]="link.icon"></i>
        <span class="link-name">{{link.link | translate}}</span>
      </a>
    </ng-container>
  </div>
</footer>

