<main>
  <app-header>
    <button  class="btn-menu-toggle" (click)="snav.toggle()">
      <i class="fa-solid fa-bars"></i>
    </button>
  </app-header>
  <mat-sidenav-container>
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="64">
      <div class="sidebar-options">
        <div class="sidebar-header">
          <button class="icon-btn secondary" (click)="snav.close()">
            <i class="fa-solid fa-x"></i>
          </button>

          <div class="logo">
            <img src="../../../assets/images/logo-blue.svg" alt="myFeragen">
            <span>{{'COMMON.MY_FERAGEN' | translate}}</span>
          </div>
        </div>

        <ul class="sidebar-menu">
          <li routerLinkActive="active" [routerLink]="['my-pets']" [routerLinkActiveOptions]="{exact:true}" (click)="snav.close()">
            <!-- class="active" -->
            <i class="fa-regular fa-dog"></i>
            <span>{{"LAYOUT.MY_DOGS" | translate }}</span>
          </li>
          <li routerLinkActive="active" [routerLink]="['/matching']" (click)="snav.close()">
            <i class="fa-solid fa-arrows-rotate"></i>
            <span>{{"LAYOUT.DOG_MATCHING" | translate }}</span>
          </li>
          <li>
            <i class="fa-regular fa-bag-shopping"></i>
            <a href="https://shop.feragen.at" target="_blank" (click)="snav.close()">{{"LAYOUT.SHOP" | translate }}</a>
          </li>
          <li routerLinkActive="active" [routerLink]="['/events']" (click)="snav.close()">
            <i class="fa-regular fa-star"></i>
            <span>{{"LAYOUT.EVENTS" | translate }}</span>
          </li>
          <li routerLinkActive="active" [routerLink]="['/account']" (click)="snav.close()">
            <i class="fa-regular fa-gear"></i>
            <span>{{"LAYOUT.ACCOUNT_SETTINGS" | translate }}</span>
          </li>
          <li (click)="toggleLang()">
            <i class="fa-regular fa-globe"></i>
            <span>{{"LAYOUT.LANGUAGE" | translate }} {{translate.currentLang | uppercase}}</span>
          </li>
          <li routerLinkActive="active" [routerLink]="['animal-search']" [routerLinkActiveOptions]="{exact:true}" (click)="snav.close()">
            <i class="fa-regular fa-search"></i>
            <span>{{"ANIMAL_SEARCH.SEARCH" | translate }}</span>
          </li>
        </ul>

        <div class="sidebar-footer">
          <button class="btn primary" [routerLink]="['/testkit/activate']" (click)="snav.close()">
            <i class="fa-regular fa-circle-plus"></i>
            <span>{{"LAYOUT.REGISTER_TESTKIT" | translate }}</span>
          </button>
        </div>
      </div>
      <!-- <button class="btn primary" (click)="logout()">{{'LOGIN.LOGOUT' | translate}}</button> -->

    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer *ngIf="mobileQuery.matches">

  </app-footer>
</main>
<app-loader></app-loader>



