import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component';
import { FooterComponent } from './footer/footer.component';
import { SharedProfileLayoutComponent } from './shared-profile-layout/shared-profile-layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    UnauthorizedLayoutComponent,
    SharedProfileLayoutComponent,
  ]
})
export class LayoutModule {}
