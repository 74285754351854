import {Directive, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective {
  private _initialized!: boolean;

  constructor(private elementRef: ElementRef) {}


  @Output() outsideClick: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this._initialized) {
            this.outsideClick.emit();
        }
        this._initialized = true;
    }
}
