<header>
  <div class="container" [ngClass]="{'notAuthorized': !isAuthorized}">
    <div class="navigation" *ngIf="isAuthorized">
      <ng-content></ng-content>
      <div class="logo-container">
        <div class="logo" [routerLink]="['']">
          <img src="../../../assets/images/logo.svg" alt="myFeragen">
          <span>{{'COMMON.MY_FERAGEN' | translate}}</span>
        </div>
      </div>

      <ul class="nav">
        <li routerLinkActive="active" [routerLink]="['']" [routerLinkActiveOptions]="{exact:true}">{{"LAYOUT.MY_ANIMALS" | translate }}</li>
        <li routerLinkActive="active" [routerLink]="['/testkit/activate']">{{"LAYOUT.ACTIVATE_TESTKIT" | translate }}</li>
        <li routerLinkActive="active" [routerLink]="['/matching']">{{"LAYOUT.MATCHING" | translate }}</li>
        <li routerLinkActive="active" [routerLink]="['/events']">{{"LAYOUT.EVENTS" | translate }}</li>
      </ul>
    </div>

    <div class="user-options">
      <div class="animal-search" routerLinkActive="active" [routerLink]="['/animal-search']"  *ngIf="isAuthorized">
        <i class="fa-solid fa-search"></i>
        {{"ANIMAL_SEARCH.SEARCH" | translate }}
      </div>
      <div class="language-switcher dropdown-container language-dropdown-container"
      [ngClass]="{'showDropdown': showLanguageDropdown}"
      (click)="onShowLanguageDropdown($event)"
      appOutsideClick
      (outsideClick)="onOutsideClick()"
      [ngClass]="{'isNotMobile': !isMobile}">
        <i class="fa-light fa-globe"></i>
        <span>{{translate.currentLang | uppercase}}</span>
        <i class="fa-solid fa-chevron-down"></i>
        <ul class="dropdown-options">
          <li (click)="changeLanguage($event, 'de')">{{"LAYOUT.DE" | translate}}</li>
          <li class="divider"></li>
          <li (click)="changeLanguage($event, 'en')">{{"LAYOUT.EN" | translate}}</li>
        </ul>
      </div>

      <div class="user dropdown-container user-settings-dropdown" *ngIf="isAuthorized">
        <i class="fa-regular fa-user"></i>
        <ul class="dropdown-options">
          <ng-container>
            <li [routerLink]="['']">{{"LAYOUT.MY_ANIMALS" | translate}}</li>
            <li [routerLink]="['/account']">{{"LAYOUT.ACCOUNT_SETTINGS" | translate }}</li>
            <li class="divider"></li>
            <li (click)="toggleLang()">{{"LAYOUT.LANGUAGE" | translate }} {{translate.currentLang | uppercase}}</li>
            <li class="divider"></li>
            <li><a href="https://www.feragen.at/myferagen/help" target="_blank">{{"LAYOUT.HELP" | translate }}</a></li>
            <li><a href="https://www.feragen.at/myferagen/faq" target="_blank">{{"LAYOUT.FAQ" | translate }}</a></li>
            <li><a href="https://feragen.at/impressum" target="_blank">{{"LAYOUT.IMPRESSUM" | translate }}</a></li>
          </ng-container>
          <li class="divider"></li>
          <li (click)="logout()">{{"LAYOUT.LOGOUT" | translate }}</li>
        </ul>
      </div>
    </div>
  </div>
</header>
