import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shared-profile-layout',
  templateUrl: './shared-profile-layout.component.html',
  styleUrls: ['./shared-profile-layout.component.scss']
})
export class SharedProfileLayoutComponent implements OnInit {
  showLanguageDropdown = false;

  constructor(
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
  }

  changeLanguage(event: Event, language: string): void {
    event.stopPropagation();
    this.translate.use(language);
    this.showLanguageDropdown = false;
  }

  onShowLanguageDropdown(event: Event): void {
    event.stopPropagation();
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  onOutsideClick(): void {
    this.showLanguageDropdown = false;
  }

}
