import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TabComponent } from './tab/tab.component';
import { AnimalComponent } from './animal/animal.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CancelRegistrationComponent } from './cancel-registration/cancel-registration.component';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { NameByLangPipe } from './name-by-lang.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { OutsideClickDirective } from './outside-click.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OneNumberDirective } from 'src/app/resources/one-number.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { HapColonPipe } from './hap-colon.pipe';
import { NgChartsModule } from 'ng2-charts';
import { DownloadResultsComponent } from './download-results/download-results.component';
import { ListComponent } from './list/list.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DisorderDetailsComponent } from './disorder-and-trait-details/disorder-and-trait-details.component';
import { NestedValuePipe } from './nested-value.pipe';
import { StatusPipe } from './status.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { MatPaginatorModule } from '@angular/material/paginator';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatDialogModule,
    TranslateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatTabsModule,
    NgChartsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    NgxFileDropModule,
    PdfViewerModule,
  ],
  declarations: [
    TabComponent,
    AnimalComponent,
    CancelRegistrationComponent,
    DropdownComponent,
    OutsideClickDirective,
    NameByLangPipe,
    OneNumberDirective,
    HapColonPipe,
    DownloadResultsComponent,
    ListComponent,
    SidebarComponent,
    DisorderDetailsComponent,
    NestedValuePipe,
    StatusPipe,
    LoaderComponent,
    DocumentPreviewComponent,
    UploadDocumentComponent,
    ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatDialogModule,
    TranslateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TabComponent,
    AnimalComponent,
    DropdownComponent,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    ScrollingModule,
    NameByLangPipe,
    OneNumberDirective,
    CommonModule,
    MatTabsModule,
    HapColonPipe,
    NgChartsModule,
    OutsideClickDirective,
    ListComponent,
    SidebarComponent,
    DisorderDetailsComponent,
    NestedValuePipe,
    StatusPipe,
    MatProgressSpinnerModule,
    LoaderComponent,
    MatPaginatorModule,
    DocumentPreviewComponent,
    UploadDocumentComponent,
  ],
  providers: [
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MatBottomSheetRef,
      useValue: {}
    },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ]

})
export class SharedModule { }
