import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, of, tap } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeService {

  constructor(
  private userService: UserService,
  private authenticationService: AuthenticationService,
  private router: Router,
  ) {}

  initialize(): Observable<any> {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const refreshToken = url.searchParams.get('refreshToken');
    const dogUuid = url.searchParams.get('dogUuid');

    if (token && refreshToken && dogUuid) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      this.authenticationService.loggedIn = true;
    }

    if (this.authenticationService.token) {
      return this.userService.getCurrentUser()
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.authenticationService.removeTokens();
            this.authenticationService.loggedIn = false;
            this.router.navigate(['login']);
            return EMPTY;
          }),
          tap((user) => {
            this.authenticationService.loggedIn = true;
          })
        )
    } else {
      return of(true);
    }
  }
}
