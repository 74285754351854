import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthInterceptor } from './services/auth.interceptor';
import { HttpLoaderFactory } from './resources/http-loader-factory';
import { ToastrModule } from 'ngx-toastr';
import { loadConfiguration } from './resources/load-configuration';
import { InitializeService } from './services/initialize.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      resetTimeoutOnDuplicate: true,
      maxOpened: 4,
      progressBar: true,
      preventDuplicates: true,
      enableHtml: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [InitializeService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
