import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  constructor(
    private media: MediaMatcher,
    private cdr: ChangeDetectorRef,
    private loginService: LoginService,
    public translate: TranslateService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1700px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  ngOnInit(): void {
  }

  logout(): void {
    this.loginService.logout()
    .subscribe();
  }

  toggleLang(): void {
    this.translate.currentLang === 'en' ? this.translate.use('de') : this.translate.use('en');
  }


}
